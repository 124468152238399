import React, { useEffect, useState } from 'react';
import '../../assets/bootstrap/bootstrap.min.css'
import '../../assets/css/aos.css'
import '../../assets/css/custom.css';
import '../../assets/css/mobile.css';
import '../../assets/css/owl.carousel.css';
import '../../assets/css/custom-style.css';
import '../../assets/css/responsive.css';
import '../../assets/css/special-classes.css';
// Import images
import enhancedImg from '../../assets/images/Homeimages/enhanced.webp';
import cervicalscreeningImg from '../../assets/images/Homeimages/cer.webp';
import longcovidImg from '../../assets/images/Homeimages/longcovid.webp';
import carehomemedicinesImg from '../../assets/images/Homeimages/carehomemedicines.webp';
import carehomeImg from '../../assets/images/Homeimages/carehome.webp';
import vaccinationImg from '../../assets/images/Homeimages/vaccination.webp';
import workflowImg from '../../assets/images/Homeimages/workflow.webp';
import prescriptionImg from '../../assets/images/Homeimages/Prescription.webp';
import claimsImg from '../../assets/images/Homeimages/claimservice.webp';
import secretarialImg from '../../assets/images/Homeimages/seceteriat.webp';
import practicesupportImg from '../../assets/images/Homeimages/practicesupport.webp';
import telephoneImg from '../../assets/images/Homeimages/reception.webp';
import core from '../../assets/images/Homeimages/corenavi.webp';
import peoplemanagementImg from '../../assets/images/Homeimages/peoplemanagement.webp';
import prof from '../../assets/images/Homeimages/professionaltraining.webp';
import arrImg from '../../assets/images/Homeimages/arrs.webp';
import { Link } from 'react-router-dom';
import CryptoJS from 'crypto-js';


const ListServiceSection = () => {

  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [data4, setData4] = useState([]);


  const encryptToken = (token, key, iv) => {
      const keyHex = CryptoJS.enc.Utf8.parse(key);
      const ivHex = CryptoJS.enc.Utf8.parse(iv);
      const encrypted = CryptoJS.AES.encrypt(token, keyHex, { iv: ivHex, mode: CryptoJS.mode.CBC });
      return encrypted.toString();
};
    
  useEffect(() => {
      const key = '8347383272422213';
      const iv = '1234567890123456';
      const token = 'Pass.lpc#webitaltech';
  
      const encryptedToken = encryptToken(token, key, iv);
      const fetchData = async () => {
        try {
          const response = await fetch(`https://localprimarycare.co.uk/api/Get-LPC-Data.php?action=getCategoriesWithServices`,
            {
              method: 'GET',
              headers: {
                Authorization: encryptedToken,
                'Content-Type': 'application/json',
              },
              referrerPolicy: 'no-referrer',
            }
          );
  
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
  
          const resp = await response.json();
          if (resp.status === 'error') {
            console.error('Error:', resp.message);
          } else {
            setData(resp.data.data);
          }
        } catch (err) {
          console.log(err.message);
        } finally {
          // setLoading(false);
        }
      };
      fetchData();
    }, []);


  return (
    <section className="index3-service-section w-100 float-left padding-top padding-bottom">
      <div className="container">
        <div className="generic-title text-center">
          <span className="small-text" data-aos="fade-up" data-aos-duration="700">Our Services</span>
          <h2 data-aos="fade-up" data-aos-duration="700">What We Can Do Together</h2>
          {/* <br /><br /><br /><br />
          <h2 data-aos="fade-up" data-aos-duration="700"></h2> */}
        </div>
{
  data?.map((item, ind)=>{
    return(
      <>
             <div className="generic-title text-center">
          <br /><br /><br /><br />
          <h2 data-aos="fade-up" data-aos-duration="700">{item.category_name}</h2>
        </div>
        <div className="index3-service-con">
        {
            item.services.map((item, ind)=>{
              return(<ServiceItem
                imgSrc={`https://localprimarycare.co.uk/public/images/service/${item.service_image}`}
                title={item.service_name}
                description={item.service_description}
                link={`/servivedetails/${item.service_id}`}
              />

              )
            })
          }
     
        </div>
      </>
    )
  })
}
   
      </div>
    </section>
  );
};
const truncateContent = (content) => {
  return content?.split(' ').slice(0, 7).join(' ') + '...';
};
const ServiceItem = ({ imgSrc, title, description, link }) => (
  <div className="item service-type-box service-img-mobile-css">
  <div className="treatment-types text-center">
      <figure>
        <img src={imgSrc} alt={`${title} image`} />
      </figure>
      <h4>{title}</h4>
      <div dangerouslySetInnerHTML={{ __html: description 
    ? truncateContent(description?.replace(/\\n|\\|\?/g, '')) 
    : truncateContent(description?.replace(/\\n|\\|\?/g, '')) 
}} />
      <Link to={link}>Read More <i className="fas fa-angle-right"></i></Link>
    </div>
  </div>
);

export default ListServiceSection;
