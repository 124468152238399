import React, { useEffect, useState } from 'react';
import video from '../assets/BannerBgVideo/bg.mp4';
import '../assets/bootstrap/bootstrap.min.css'
import '../assets/css/aos.css'
import '../assets/css/custom.css';
import '../assets/css/mobile.css';
// import '../assets/css/custom-style.css';
import '../assets/css/owl.carousel.css';
import { Link } from 'react-router-dom';
import CryptoJS from 'crypto-js';


function BannerSection() {
  const [data, setData] = useState(null);

    const encryptToken = (token, key, iv) => {
        const keyHex = CryptoJS.enc.Utf8.parse(key);
        const ivHex = CryptoJS.enc.Utf8.parse(iv);
        const encrypted = CryptoJS.AES.encrypt(token, keyHex, { iv: ivHex, mode: CryptoJS.mode.CBC });
        return encrypted.toString();
      };
    useEffect(() => {
        const key = '8347383272422213';
        const iv = '1234567890123456';
        const token = 'Pass.lpc#webitaltech';
    
        const encryptedToken = encryptToken(token, key, iv);
        const fetchData = async () => {
          try {
            const response = await fetch(`https://localprimarycare.co.uk/api/Get-LPC-Data.php?action=getBanners`,
              {
                method: 'GET',
                headers: {
                  Authorization: encryptedToken,
                  'Content-Type': 'application/json',
                },
                referrerPolicy: 'no-referrer',
              }
            );
    
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
    
            const resp = await response.json();
            if (resp.status === 'error') {
              console.error('Error:', resp.message);
            } else {
              setData(resp.data.data[0]);
              // console.log(resp);
              
            }
          } catch (err) {
            console.log(err.message);
          } finally {
            // setLoading(false);
          }
        };
    
        fetchData();
      }, []);
    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    
    return (
        <section className="banner-main-section w-100 float-left d-flex align-items-center">
            <video className="video-slide" src={video} autoPlay loop muted></video>
            <div className="container-fluid">
                <div className="banner-inner-con">
                    <div className="banner-txt-section ">
                    <span className="small-txt position-relative">
                            {data?.subheading}
                        </span>
                        <h1 style={{fontSize: "65px"}} className='hometitlee'>{data?.heading}</h1>
                        <div dangerouslySetInnerHTML={{ __html: data?.text}}/>
                        <div className="banner-btns generic-btn" >
                            {/* <Link to={"/#contact"} onClick={() => scrollToSection('contact')}>Book Appointment</Link> */}
                            <Link to={"/about"}>Read More</Link>
                        </div>
                    </div>
                    {/* 
                    <div className="banner-images-con" data-aos="fade-up" data-aos-duration="700">
                        <figure className="mb-0">
                            <img src="assets/images/banner-right-img1.png" alt="banner-right-img1" />
                        </figure>
                        <figure className="mb-0">
                            <img src="assets/images/banner-right-img2.png" alt="banner-right-img2" />
                        </figure>
                        <figure className="mb-0">
                            <img src="assets/images/banner-right-img3.png" alt="banner-right-img3" />
                        </figure>
                    </div>
                    */}
                </div>
                <div class="social-links" data-aos="fade-up" data-aos-duration="700">
                <ul class="list-unstyled mb-0">
                    <li><a class="facebook" href="https://www.facebook.com/BlackburnLocalPrimaryCare" target="_blank" ><i class="fab fa-facebook-f"></i></a>
                    </li>
                    <li><a class="twitter"
                            href="https://twitter.com/lpcfederation?lang=en" target="_blank"><i
                                class="fab fa-twitter"></i></a></li>
                    <li class="mb-0"><a class="instagram"
                            href="https://www.instagram.com/lpcfederation/" target="_blank"><i
                                class="fab fa-instagram"></i></a>
                    </li>
                </ul>
            </div>      
            </div>
        </section>
    );
}

export default BannerSection;
