import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import img1 from "../../assets/images/Homeimages/claimservice.webp"
import img2 from "../../assets/images/Homeimages/workflow.webp"
import img3 from "../../assets/images/Homeimages/Prescription.webp"
import img4 from "../../assets/images/Homeimages/longcovid.webp"
import img5 from "../../assets/images/Homeimages/carehomemed.webp"
import img6 from "../../assets/images/Homeimages/peoplemanagement.webp"
import img7 from "../../assets/images/Homeimages/practicesupport.webp"
import img8 from "../../assets/images/Homeimages/carehomemedicines.webp"
import img9 from "../../assets/images/Homeimages/supportingpcn.jpg"
import img10 from "../../assets/images/Homeimages/carehomemedicines.webp"
import img11 from "../../assets/images/Homeimages/arrs.webp"
// import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { MdOutlineKeyboardArrowLeft,MdKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import CryptoJS from 'crypto-js';


const items = [
  {
    imgSrc: img1,
    altText: "treatment-img2",
    title: "Secretarial Hub",
    description: "The Secretarial Hub is your centralised solution for efficient referral ...",
    link: "/secretarialhub",
  },
  {
    imgSrc: img2,
    altText: "treatment-img3",
    title: "Workflow Hub",
    description: "Our Workflow Hub is a centralised solution for streamlined processing...",
    link: "/workflow",
  },
  {
    imgSrc: img3,
    altText: "treatment-img4",
    title: "Prescription Hub",
    description: "The Prescription Hub is your central solution for streamlined ordering ...",
    link: "/prescriptionhub",
  },
  {
    imgSrc: img4,
    altText: "treatment-img5",
    title: "Long Covid Service",
    description: "LPC provide essential representation on the Long Covid Service ...",
    link: "/longcovid",
  },
  {
    imgSrc: img5,
    altText: "treatment-img6",
    title: "Care Home Virtual Ward Rounds",
    description: "Experience proactive and personalised care for residents with our Care...",
    link: "/virtualcarehome",
  },
  {
    imgSrc: img6,
    altText: "treatment-img7",
    title: "People Management Services",
    description: "Our People Management Services Team is where expertise meets ...",
    link: "/peoplemanage",
  },
  {
    imgSrc: img7,
    altText: "treatment-img8",
    title: "Practice Support Service",
    description: "Our Practice Support Team is the backbone of efficiency and reliability ...",
    link: "/practicesupport",
  },
  {
    imgSrc: img8,
    altText: "treatment-img9",
    title: "Care Home Medicines Training",
    description: "Our organisation specializes in delivering comprehensive  ...",
    link: "/homecaremedicine",
  },
  {
    imgSrc: img9,
    altText: "treatment-img10",
    title: "Supporting PCNs",
    description: "PCNs, or Primary Care Networks, are fundamental components of the NHS ...",
    link: "/services",
  },
  {
    imgSrc: img11,
    altText: "treatment-img11",
    title: "ARRS",
    description: "As the primary employer on behalf of all our Primary ...",
    link: "/services",
  },
];

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1024 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 1024, min: 768 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 768, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
const CustomControls = ({ previous, next }) => {
    return (
      <div className="space-x-4" style={{display: "flex" ,justifyContent: "center", marginTop:"18px"}}>
        <button
          onClick={previous}
          className="p-2 "
          style={{border: "none", outline: "none" ,background: "none", color: "#298d9c", cursor:"pointer"}}
        >
          <MdOutlineKeyboardArrowLeft className="" style={{fontSize: "55px"}}/>
        </button>
        <button
          onClick={next}
          className="p-2"
          style={{border: "none",outline: "none" ,background: "none", color: "#298d9c", cursor:"pointer"}}
        >
          <MdKeyboardArrowRight style={{fontSize: "55px"}} />
        </button>
      </div>
    );
  };
  const PortfolioCarousel = () => {
    const [data, setData] = useState([]);
    // const [data2, setData2] = useState([]);
    // const [data3, setData3] = useState([]);
    // const [data4, setData4] = useState([]);
  
  
    const encryptToken = (token, key, iv) => {
        const keyHex = CryptoJS.enc.Utf8.parse(key);
        const ivHex = CryptoJS.enc.Utf8.parse(iv);
        const encrypted = CryptoJS.AES.encrypt(token, keyHex, { iv: ivHex, mode: CryptoJS.mode.CBC });
        return encrypted.toString();
      };
    useEffect(() => {
        const key = '8347383272422213';
        const iv = '1234567890123456';
        const token = 'Pass.lpc#webitaltech';
    
        const encryptedToken = encryptToken(token, key, iv);
        const fetchData = async () => {
          try {
            const response = await fetch(`https://localprimarycare.co.uk/api/Get-LPC-Data.php?action=getServices`,
              {
                method: 'GET',
                headers: {
                  Authorization: encryptedToken,
                  'Content-Type': 'application/json',
                },
                referrerPolicy: 'no-referrer',
              }
            );
    
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
    
            const resp = await response.json();
            if (resp.status === 'error') {
              console.error('Error:', resp.message);
            } else {
              // setData();
              // const filteredData1 = resp.data.data.filter(item => item.category === "Clinical Services");
              // const filteredData2 = resp.data.data.filter(item => item.category === "Administrative Services");
              // const filteredData3 = resp.data.data.filter(item => item.category === "Professional Development and Training");
              // const filteredData4 = resp.data.data.filter(item => item.category === "Workforce Support");
  
              // setData(filteredData1);
              // setData2(filteredData2)
              // setData3(filteredData3)
              // setData4(filteredData4)
  
              setData(resp.data.data)

              // console.log(resp);
              
            }
          } catch (err) {
            console.log(err.message);
          } finally {
            // setLoading(false);
          }
        };
    
        fetchData();
      }, []);

      const truncateContent = (content) => {
        return content?.split(' ').slice(0, 8).join(' ') + '...';
      };
    return (
      <div>
        <Carousel
          responsive={responsive}
          customButtonGroup={<CustomControls />}
          arrows={false}
          renderButtonGroupOutside={true} // Add this line to render buttons outside the carousel
        >
          {data?.map((item, index) => (
            <div key={index} className="item p-md-1">
              <div className="treatment-types text-center p-4">
                <figure >
                  <img src={`https://localprimarycare.co.uk/public/images/service/${item.image}`} alt={item.altText} className="w-full h-auto" style={{borderRadius: "15px"}} />
                </figure>
                <h4 className="text-xl font-semibold mt-4">{item.service}</h4>
                {/* <p className="mt-2 text-gray-600">{item.description}</p> */}
                <div dangerouslySetInnerHTML={{ __html: item.description 
    ? truncateContent(item.description?.replace(/\\n|\\|\?/g, '')) 
    : truncateContent(item.description?.replace(/\\n|\\|\?/g, '')) 
}}/>
                <Link to={`/servivedetails/${item.id}`} className="text-blue-500 mt-4 inline-block">
                  Read More <i className="fas fa-angle-right"></i>
                </Link>
              </div>
            </div>
          ))}
        </Carousel>
        {/* <CustomControls
          previous={() => document.querySelector('.react-multi-carousel-item--active .react-multi-carousel-arrow--left').click()}
          next={() => document.querySelector('.react-multi-carousel-item--active .react-multi-carousel-arrow--right').click()}
        /> */}
      </div>
    );
  };;

export default PortfolioCarousel;




