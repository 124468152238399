import React, { useEffect } from 'react'
import BlogSection from '../Components/BlogsSlider'
import { Link } from 'react-router-dom'

const Blogs = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <section className="service-banner sub-banner-section w-100 float-left d-flex align-items-center mb-5 ">
    <div className="container">
      <div className="sub-banner-inner-con text-center">
        <h1 data-aos="fade-up" data-aos-duration={700}>
          Blogs Coming Soon...
        </h1>
        {/* <p data-aos="fade-up" data-aos-duration={700}>
          Noidunt eget semper nec ruam sed hendrerit morbi aeu feliseao augue{" "}
          <br />
          pellentesue veniam morbi acer.
        </p> */}
        <nav aria-label="breadcrumb" data-aos="fade-up" data-aos-duration={700}>
          <ol className="breadcrumb d-inline-block mb-0">
            <li className="breadcrumb-item d-inline-block">
              <Link to={"/"}>Home</Link>
            </li>
            <li
              className="breadcrumb-item active text-white d-inline-block"
              aria-current="page"
            >
           Blogs
            </li>
          </ol>
        </nav>
      </div>
    </div>
  </section>
  {/* BANNER SECTION END */}
      {/* <BlogSection/> */}
    </>
  )
}

export default Blogs
