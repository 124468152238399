import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CryptoJS from 'crypto-js'; 


const serviceItems = [
  "ST GEORGES SURGERY",
  "WILIAM HOPWOD STREET SURGERY",
  "STEPPING STONE PRACTICE",
  "HOLLINS GROVE SURGERY",
  "SPRING FENISCO HEALTH LINK",
  "BROWNHILL SURGERY",
  "LITTLE HARWOOD HEALTH CENTRE",
  "PRIMROSE BANK MEDICAL CENTER",
  "ROE LEE SURGERY",
  "EWOOD MEDICAL CENTRE",
  "LIMEFIELD SURGERY",
  "OAKENHURST SURGERY",
  "REDLAM SURGERY",
  "THE FAMILY PRACTICE",
  "WITTON MEDICAL CENTRE",
  "DARWEN HEALTH CENTRE",
  "DARWEN HEALTH LINK",
  "SHIFA SURGERY BANGOR STREET",
  "OLIVE MEDICAL CENTRE",
];

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1024 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 1024, min: 768 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 768, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};


const ServiceCarousel = () => {
  const [data, setData] = useState([]);

  const encryptToken = (token, key, iv) => {
      const keyHex = CryptoJS.enc.Utf8.parse(key);
      const ivHex = CryptoJS.enc.Utf8.parse(iv);
      const encrypted = CryptoJS.AES.encrypt(token, keyHex, { iv: ivHex, mode: CryptoJS.mode.CBC });
      return encrypted.toString();
    };
    
  useEffect(() => {
      const key = '8347383272422213';
      const iv = '1234567890123456';
      const token = 'Pass.lpc#webitaltech';
  
      const encryptedToken = encryptToken(token, key, iv);
      const fetchData = async () => {
        try {
          const response = await fetch(`https://localprimarycare.co.uk/api/Get-LPC-Data.php?action=getMemberPractices`,
            {
              method: 'GET',
              headers: {
                Authorization: encryptedToken,
                'Content-Type': 'application/json',
              },
              referrerPolicy: 'no-referrer',
            }
          );
  
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
  
          const resp = await response.json();
          if (resp.status === 'error') {
            console.error('Error:', resp.message);
          } else {
            setData(resp.data.data);
            // console.log(resp);
            
          }
        } catch (err) {
          console.log(err.message);
        } finally {
          // setLoading(false);
        }
      };
  
      fetchData();
    }, []);
    
  return (
 <div>
        <Carousel 
          responsive={responsive}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={3000}
        //   customButtonGroup={<CustomControls />}
          arrows={false}
          
        >
          {data?.map((item, index) => (
            <div className="service-carousel-item">
            <div key={index} className="service-type-boxxx text-center p-4" data-aos="fade-up" data-aos-duration="700">
              <h4>{item.title}</h4>
            </div>
            </div>
          ))}
        </Carousel>
        </div>
  );
};

export default ServiceCarousel;
